// Fonts
$font-family-base: 'IBM Plex Sans', sans-serif !default;
$font-family-monospace: 'IBM Plex Mono', monospace !default;

$body-bg: #e7e7e7;

$black: #000 !default;
$blue: #0d6efd !default;
$pink: #d63384 !default;
$dark: #333 !default;
$light: #fff !default;
$gray: #e7e7e7 !default;
$dark-gray: #4f4f4f !default;
$border-gray: #d9dadb !default;
$border-dark-gray: #bdbdbd !default;
$text-gray: #b4b4b4 !default;
$bg-gray: #828282 !default;
$bg-light-gray: #e0e0e0 !default;
$bg-dark-gray: #4f4f4f !default;
$primary: #8540f5;

$sub-sup-font-size: .875rem !default;

$canvas-width: 384px;
$canvas_contribution_height: 128px;
$canvas-height: $canvas_contribution_height * 3;

$canvas-width-lg: 768px;
$canvas_contribution_height-lg: 256px;
$canvas-height-lg: $canvas_contribution_height-lg * 3;

$btn-close-width: .8em;
$btn-close-color: $light;

$navbar-toggler-padding-x: 0;
$navbar-toggler-padding-y: 0;

$font-weight-bolder: 600 !default;

$accordion-icon-width: 1rem;

$modal-backdrop-bg: $bg-light-gray !default;
$modal-sm: 471px !default;

$spinner-width: 2.8rem !default;

$badge-border-radius: 0.313rem;

$navbar-height: 5rem;

// $border-radius-pill: 1rem; // 50rem