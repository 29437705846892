.app-header {
    @include media-breakpoint-down(md) {
        padding-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @include media-breakpoint-up(md) {
        padding: 1rem 0;
      }
}

.navbar {
  z-index: 11;
  top: 0;
  left: 0;
  @include media-breakpoint-up(md) {
    min-height: $navbar-height;
  }
  @include media-breakpoint-down(md) {
    min-height: 60px;
  }
}


.user-dropdown-content {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: rgb(0 0 0 / 15%) 3px 3px 6px -3px;

  .dropdown-item {
    display: flex;
    align-items: center;
    svg {
      font-size: 1.3rem;
      margin-right: 0.5rem;
    }
  }
  li {
    a {
      padding-left: .75rem;
    }
    &:last-child {
      .dropdown-item {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }
  }
}

// .user-dropdown {
//   .dropdown-toggle {
//     &:after {
//       margin-right: 0.5em;
//     }
//   }
// }

.btn-dropdown {
  &.open {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.user-dropdown {
  .account-circle {
    width: 24px;
    height: 24px;
  }
  img {
    width: 24px;
    height: 24px;
  }
}