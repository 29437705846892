.prompt-slot {
    &:first-child {
        .prompt-slot-contribution {
          border-bottom: 1px solid $gray;
        }
      }
      &:nth-child(2) {
        .prompt-slot-contribution {
            border-bottom: 1px solid $gray;
        }
      }
      .prompt-block {
        @include media-breakpoint-up(md) {
          height: $canvas_contribution_height;
        }
    }
    // .contribution-wrapper {
    //   flex: 2;
    // }
    .actions-wrapper {
      width: 3rem;
      // flex: 1;
      // .prompt-slot-actions {
      //   button {
      //       padding: .5rem 2rem;
      //   }
      // }
    }
    .prompt-slot-utils {
        &.placement-second {
            margin-top: $canvas_contribution_height;
        }
        &.placement-third {
            margin-top: $canvas_contribution_height * 2;
        }
    }
}

.prompt-block {
  .input-group {
    .input-group-button {
      svg {
        font-size: 1.2em;
      }
    }
  }
}

.prompt-contribution-wrapper {
  &.visitor {
    width: $canvas-width-lg;
    .prompt-slot-contribution {
      height: $canvas_contribution_height-lg;
    }
  }
  &.new {
    width: $canvas-width;
    margin: 0 auto;
  }
  &.contributor {
    width: $canvas-width;
    margin: 0 auto;
  }
}


.prompt-slot-contribution {
  width: 100%;
  height: $canvas_contribution_height;
  &:first-child {
    border-bottom: 1px solid $gray;
  }
  &:nth-child(2) {
    border-bottom: 1px solid $gray;
  }
}

