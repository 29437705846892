
.dummy-canvas {
    width: 384px;
    height: 128px;
    background-color: $white;
    margin: 0 auto;
}

.popover-content {
    width: 112px;
    border-radius: 5px;
    ul {
        li {
            border-bottom: 1px solid $border-gray;
            padding: 5px 0;
            font-size: 14px;
            cursor: pointer;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
}

.popover-content {
    position: absolute;
    left: 60px;
    top: 0;
    z-index: 8;
    .list-group {
        .list-group-item {
            display: flex;
            justify-content: flex-start;
            gap: 5px;
            &.active {
                background-color: transparent;
                border-color: $border-gray;
                color: $black;
                .check-icon {
                    visibility: visible;
                }
            }
            .check-icon {
                visibility: hidden;
                font-size: 20px;
            }
            .square {
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .triangle{
        width: 0;
        height: 0;
        border-width: 6.5px 13px 6.5px 0;
        border-color: transparent $white transparent transparent;
        border-style: solid;
        position: absolute;
        left: -10px;
        top: 13px;
    }
}

.draw {
    cursor: crosshair;
}
.erase {
    cursor: cell;
}
.eye-drop {
    cursor: crosshair;
}
