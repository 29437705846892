
body {
 background-color: #fff;
}

.layout {
  height: 100%;
}

.layout {
  &.gradient {
    @include gradient-y($body-bg, $white, 0%, 100%);
  }
}

.logo-type {
  font-family: 'Perfect';
  line-height: 100%;
  font-size: 2rem;
  font-weight: 400;
  background: linear-gradient(95.38deg, #5956FD 0%, #84A9FF 34.74%, #9E9AFF 67.92%, #B55FFF 99.55%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.main-title {
  font-family: 'Perfect';
  font-size: 56px;
  font-weight: 400;
  text-align: center;
  background: linear-gradient(95.38deg, #5956FD 0%, #84A9FF 34.74%, #9E9AFF 67.92%, #B55FFF 99.55%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  line-height: 1;
  @include media-breakpoint-up(sm) {
    font-size: 96px;
  }
}

.explainer {
  .explainer-inner {
    border: 3px solid #DDDDDD;
  }
}

.app-header {
  .logo {
    a {
      color: $dark;
      font-weight: 700;
    }
  }
}

.header-buttons {
  button {
    max-width: 9.063rem;
  }
}

.navbar-nav {
  margin-left: auto;
}

.app-content {
  margin-top: $navbar-height;
  min-height: 100vh;
  @include media-breakpoint-down(sm) {
    margin-top: 6rem;
  }
}

.app-footer {
  margin-top: auto;
  padding: 1.5rem 0;
}

.btn-toggler {
  font-size: 1.5em;
  color: $black;
  padding: 0;
  width: 1.5em;
  height: 1.5em;
}

.alert-install-wallet {
  a {
    color: $dark;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.text-sup {
  font-size: $sub-sup-font-size;
}

.container {
  max-width: 960px;
}

.Content {
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 5;
  overflow: hidden;
}

.Button {
  color: $black;
  text-decoration: none;
  background: #ececec;
  padding: 0.3rem 0.8rem;
  border-radius: 0.3rem;
  cursor: pointer;
  border: none;
  font-size: inherit;
}

.Button:hover {
  background-color: $blue;
  color: $white;
}

.Button:focus {
  background-color: $black;
  color: $white;
}

.Button:active {
  color: $white;
  color: $black;
}

.Explainer {
  display: flex;
  gap: 0.3rem;
}

.Explainer > div {
  border: 3px solid black;
  padding: 3rem;
  flex: 1;
  min-height: 19rem;
}

.Prompt-List {
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
}

.Prompt-Image {
  height: 5rem;
  width: 15rem;
  overflow: hidden;
  border: 3px solid black;
}

.Prompt-Image:first-child {
  border-bottom: none;
}

.Prompt-Image:last-child {
  border-top: none;
}

.Prompt-Slot {
  display: flex;
  justify-content: space-between;
}

.Prompt-Slot-Participant {
  margin-right: 1rem;
}


.Prompt-Slot {
  &:first-child {
    .Prompt-Slot-Contribution {
      border-bottom: 0;
    }
  }
  &:nth-child(2) {
    .Prompt-Slot-Contribution {
      border-bottom: 0;
    }
  }
}

.Prompt-Slot-Contribution {
  border: 2px solid $gray;
  padding: 1rem;
}

.Contribution-Image {
  width: 512px;
  height: 256px;
}

.Contribution-Image img {
  width: 100%;
  height: 100%;
}

.prompt-slot-canvas {
  padding: 0;
  background-color: #FFFFFF;

  img {
    image-rendering: pixelated;
  }
}

.Ellipsis {
  text-overflow: ellipsis;
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

a {
  color: $blue;
}

a:hover {
  color: $black;
}

h3 {
  font-weight: normal;
  margin-bottom: 0.3rem;
}

.Prompt-Countdown {
  display: flex;
  gap: 1rem;
}

.Prompt-Countdown span {
  display: block;
  font-size: 1.6rem;
}

.ChosenColor {
  border: 1px solid #565e64;
}

.btn {
  .badge-limit {
    top: -1px;
    font-size: 0.6rem;
  }
}

button, input {
  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
}

.form-control {
  &:disabled {
    &:focus {
      background-color: #e9ecef;
    }
  }
}

.form-control[readonly] {
  &:focus {
    background-color: #e9ecef;
    box-shadow: none;
    border-color: #ced4da;
  }
}

.form-control {
  background-color: $white;
  &:focus {
    background-color: $white;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.accordion {
  max-width: 550px;
  margin: 0 auto;
}

.accordion-item {
  background: none;
}
.accordion-button {
  background: none;
}
.accordion-button:not(.collapsed) {
  color: $black;
  box-shadow: none;
  background: none;
}

.lead {
  max-width: 550px;
  margin: 2rem auto;
}