@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("IBMPlexSans-SemiBold.ttf") format("truetype"), url("IBMPlexSans-SemiBold.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("IBMPlexSans-SemiBoldItalic.ttf") format("truetype"), url("IBMPlexSans-SemiBoldItalic.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("IBMPlexSans-Bold.ttf") format("truetype"), url("IBMPlexSans-Bold.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("IBMPlexSans-BoldItalic.ttf") format("truetype"), url("IBMPlexSans-BoldItalic.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("IBMPlexSans-ExtraLight.ttf") format("truetype"), url("IBMPlexSans-ExtraLight.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("IBMPlexSans-ExtraLightItalic.ttf") format("truetype"), url("IBMPlexSans-ExtraLightItalic.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("IBMPlexSans-Light.ttf") format("truetype"), url("IBMPlexSans-Light.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("IBMPlexSans-Medium.ttf") format("truetype"), url("IBMPlexSans-Medium.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("IBMPlexSans-MediumItalic.ttf") format("truetype"), url("IBMPlexSans-MediumItalic.ttf") format("ttf");
}