.user-tabs {
  border-bottom: 1px solid $border-dark-gray;
  .nav-pills {
    .nav-link {
      color: $black;
      border-radius: 0;
      &.active {
        background-color: transparent;
        border-bottom: 3px solid $black;
        color: $black;
      }
    }
    @include media-breakpoint-down(sm) {
      .nav-item {
        font-size: $sub-sup-font-size;
      }
    }
  }
}

.address-frame {
  font-size: 20px;
  border-radius: 10px;
}

.address-number {
  font-family: $font-family-monospace;
  font-weight: 400;
}

.address-text {
  font-size: 20px;
}

.user-profile {
  .account-circle {
    width: 120px;
    height: 120px;
  }
}