@media (max-width: 900px) {

  .Explainer {
    flex-direction: column;
  }

  .Explainer > div {
    min-height: 6rem;
  }

  .Ellipsis {
    max-width: 5rem;
  }

  .Page-Aside {
    padding-left: 0;
  }

  .Prompt-Slot {
    flex-direction: column;
  }
}

@media (min-width: 900px) {
  .PageHeader {
    flex-basis: 100%;
  }

  .PageGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }
  .PageContent {
    display: flex;
    flex-direction: column;
  }

}
