@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Roboto-Regular.ttf") format("truetype"), url("Roboto-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("Roboto-BlackItalic.ttf") format("truetype"), url("Roboto-BlackItalic.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("Roboto-Thin.ttf") format("truetype"), url("Roboto-Thin.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("Roboto-Light.ttf") format("truetype"), url("Roboto-Light.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Roboto-Medium.ttf") format("truetype"), url("Roboto-Medium.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Roboto-Bold.ttf") format("truetype"), url("Roboto-Bold.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("Roboto-BoldItalic.ttf") format("truetype"), url("Roboto-BoldItalic.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("Roboto-LightItalic.ttf") format("truetype"), url("Roboto-LightItalic.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("Roboto-MediumItalic.ttf") format("truetype"), url("Roboto-MediumItalic.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("RobotoMono-Regular.ttf") format("truetype"), url("RobotoMono-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("RobotoMono-Bold.ttf") format("truetype"), url("RobotoMono-Bold.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("RobotoMono-Light.ttf") format("truetype"), url("RobotoMono-Light.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("RobotoMono-Thin.ttf") format("truetype"), url("RobotoMono-Thin.ttf") format("ttf");
}
