@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("IBMPlexMono-SemiBold.ttf") format("truetype"), url("IBMPlexMono-SemiBold.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("IBMPlexMono-BoldItalic.ttf") format("truetype"), url("IBMPlexMono-BoldItalic.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("IBMPlexMono-Bold.ttf") format("truetype"), url("IBMPlexMono-Bold.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("IBMPlexMono-Medium.ttf") format("truetype"), url("IBMPlexMono-Medium.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("IBMPlexMono-Regular.ttf") format("truetype"), url("IBMPlexMono-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("IBMPlexMono-MediumItalic.ttf") format("truetype"), url("IBMPlexMono-MediumItalic.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("IBMPlexMono-Thin.ttf") format("truetype"), url("IBMPlexMono-Thin.ttf") format("ttf");
}
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("IBMPlexMono-ThinItalic.ttf") format("truetype"), url("IBMPlexMono-ThinItalic.ttf") format("ttf");
}