.prompt-slot {
    .prompt-time {
        word-break: break-all;
    }
}

.contributions-wrapper {
    .prompt-block {
        @include media-breakpoint-up(md) {
            height: $canvas_contribution_height;
        }
        @include media-breakpoint-up(lg) {
            height: $canvas_contribution_height;
        }
    }
}

.actions-wrapper {
    .placement-second {
        margin-top: $canvas_contribution_height;
    }
    .placement-third {
        margin-top: $canvas_contribution_height * 2;
    }
}

.final-column {
    .balance-text {
        bottom: -30px;
    }
}

.visitor-view {
    .account-card {
        img {
            width: 30px;
            height: 30px;
        }
    }
    .account-circle {
        width: 30px;
        height: 30px;
    }
}

.account-collaborators {
    .account-card {
        img {
            width: 75px;
            height: 75px;
        }
    }
    .account-circle {
        width: 75px;
        height: 75px;
    }
}

.contributor-cards {
    .account-circle {
        width: 30px;
        height: 30px;
    }
    img {
        width: 30px;
        height: 30px;
    }
}