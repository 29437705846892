
.transaction-modal {
  .modal-indicator {
    width: 63px;
    height: 63px;
  }
  hr {
    opacity: 1;
    border-top: 2px dashed $dark;
  }
  .modal-summary {
    font-family: $font-family-monospace;
    font-size: 1.125rem;
  }
  .modal-message {
    font-size: 1.375rem;
  }
}

.modal-overlay {
  background: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
  z-index: $zindex-modal-backdrop;
}