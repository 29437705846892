.prompt-card-link {
    margin: 0 auto;
}

.btn-color-picker{
    outline: none;
    box-shadow:none !important;
    border:0;
}


.contributor-circles {
    margin-left: 10px;
    .contributor-circle {
        img {
            width: 25px;
            height: 25px;
            margin-left: -13px;
            transition: all .3s;
            z-index: 0;
            cursor: pointer;
        }
        &:hover {
            z-index: 2;
            transition: all .3s;
        }
    }
}


.prompt-card {
    margin: 0 auto;
    transition: transform .3s;
    &:hover {
            &.transformed {
                transform: translateY(-5px);
                transition: transform .3s;
            }
    }
}

.prompt-card-bottom {
    background-color: $gray-200;
}

// .custom-badge {
//     padding: 0.5rem 0.7rem;
//     font-size: 0.8rem;
//     background-color: $gray-400;
// }

.prompt-name {
    border-bottom: 1px solid $gray-400;
}

.prompt-card-footer {
    height: 40px;
}