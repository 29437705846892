.account-card {
  min-width: 213px;
  border-radius: 1.25rem;
  .account-link {
    font-weight: $font-weight-bolder;
  }
  .account-link-sm {
    font-size: 1.125rem;
  }
  .account-link-lg {
    font-size: 2.25rem;
  }
}

.account-collaborators {
  .account-card {
    min-height: 207px;
  }
}

.account-card:hover {
  background-color: $gray-100 !important;
}