.edit-profile-form {
  margin: 0 auto;
  .form-block {
    max-width: 290px;
  }
  .image-upload {
    label {
      font-size: 3rem;
      width: 120px;
      height: 120px;
      background-color: $white;
      cursor: pointer;
    }
  }
}

.image-preview {
  width: 120px;
  height: 120px;
}

.upload-image-icon {
  background-color: rgba(15, 20, 25, 0.25);
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 27px;
  color: #dee2e6;
  &:hover {
    background-color: rgba(15, 20, 25, 0.75);
  }
}

.resize-modal {
  canvas {
    position: absolute;
    z-index: 0;
    left: 0;
  }
}

.resize-frame {
  width: 450px;
  height: 450px;
  z-index: 1;
  border: 4px solid $primary;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(230 236 240 / 70%) 0px 0px 0px 9999px;
  margin-top: 30px;
}

.scale-input {
  margin-top: 50px;
  z-index: 1;
  color: $secondary;
}