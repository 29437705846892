.prompts-list-container {
    margin: 0 auto;
    width: $canvas-width;
}  

// Old versions of Safari 14.1
@supports not (gap: 0.5rem) {
    .prompts-list-bar {
        margin: -0.5em;
    }
    .prompts-list-bar > * {
        margin: 0.5em;
    }
}

.prompts-summary {
    .summary-number {
        font-family: $font-family-monospace;
        font-weight: $font-weight-bolder;
      //  @include media-breakpoint-down(sm) {
      //      font-size: 16px;
      //    }
    }
    .summary-text {
        font-family: $font-family-monospace;
        font-weight: 400;
        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }
    .currency-symbol {
        font-family: $font-family-base;
    }
}

